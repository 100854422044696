// External Libraries
import { Link, MetaFunction } from '@remix-run/react';

// Images
import PanMedicalLogo from '~/images/medlink/panmed.png';
import MedPatient from '~/images/medlink/mdi-patient-outline.svg';
import MedCompany from '~/images/medlink/mdi-company.svg';
import MedInsurance from '~/images/medlink/streamline-insurance-hand.svg';

// Utils
import { setPageTitle } from '~/utils/config';
import { cn } from '~/lib/utils';
import { ModeToggle } from '~/components/mode-toggle';

const options = [
  { value: 'patient', label: 'Patient', icon: MedPatient, disabled: false },
  { value: 'company', label: 'Company', icon: MedCompany, disabled: true },
  { value: 'insurance', label: 'Insurance', icon: MedInsurance, disabled: false },
]

export const meta: MetaFunction = () => {
  return [
    { title: setPageTitle("Home") },
    {
      name: "description",
      content: "Sign in to your account to access your dashboard.",
    },
  ];
};

export default function HomePage() {
  return (
    <div className={cn("h-screen bg-background relative flex flex-col")}>
      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-gradient-to-br from-primary/20 via-primary/5 to-transparent blur-3xl animate-slow-drift" />
        <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-gradient-to-tl from-secondary/20 via-secondary/5 to-transparent blur-3xl animate-slow-drift-reverse" />
        <div className="absolute inset-0 bg-grid-white/[0.02] bg-[size:50px_50px]" />
      </div>

      {/* Header with glass effect */}
      <header className="sticky top-0 z-50 w-full border-b border-border/40 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="container mx-auto px-3 sm:px-4 h-14 sm:h-16 flex items-center justify-between">
          <span className="text-lg sm:text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary via-primary/80 to-secondary animate-gradient">
            Medlink
          </span>
          <ModeToggle />
        </div>
      </header>

      <main className="flex-1 container mx-auto px-3 sm:px-4 py-4 sm:py-8 relative z-10 overflow-hidden">
        <div className="h-full max-w-screen-xl mx-auto space-y-6 sm:space-y-8">
          {/* Logo Section */}
          <div className="text-center relative">
            <div className="absolute inset-0 flex items-center justify-center blur-3xl opacity-20">
              <div className="w-24 sm:w-32 h-24 sm:h-32 bg-primary/20 rounded-full" />
            </div>
            <img 
              className="mx-auto w-24 sm:w-32 md:w-48 transition-all duration-500 hover:scale-105 relative" 
              src={PanMedicalLogo} 
              alt="Pan Medical Logo" 
            />
          </div>

          {/* Hero Section */}
          <div className="text-center space-y-2 relative">
            <div className="absolute inset-0 flex items-center justify-center blur-3xl opacity-10">
              <div className="w-full h-6 sm:h-8 bg-secondary/20 rounded-full" />
            </div>
            <h1 className="text-xl sm:text-2xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary via-primary/80 to-secondary animate-gradient relative">
              Welcome to Pan Medical
            </h1>
            <p className="text-xs sm:text-sm md:text-base text-muted-foreground/80 max-w-2xl mx-auto px-4">
              Choose your account type to get started with our services
            </p>
          </div>
          
          {/* Cards Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4 md:gap-6 max-w-4xl mx-auto px-2 sm:px-4">
            {options.map((option) => (
              <Link
                key={option.value}
                to={`/login?type=${option.value}`}
                className={cn(
                  "group relative p-4 sm:p-6 md:p-8 rounded-xl sm:rounded-2xl transition-all duration-500",
                  "bg-card/30 backdrop-blur-sm border border-border/50",
                  "hover:bg-card/50 hover:border-primary/50 hover:shadow-lg hover:shadow-primary/10",
                  "hover:-translate-y-1 transform-gpu",
                  option.disabled ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''
                )}
                viewTransition
              >
                {/* Card Content */}
                <div className="flex flex-col items-center space-y-4 sm:space-y-6 relative z-10">
                  <div className={cn(
                    "p-3 sm:p-4 rounded-lg sm:rounded-xl transition-all duration-500",
                    "bg-secondary/5 group-hover:bg-secondary/10",
                    "ring-1 ring-border/50 group-hover:ring-primary/50",
                    "group-hover:scale-105"
                  )}>
                    <img 
                      className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 transition-transform duration-500 group-hover:scale-110" 
                      src={option.icon} 
                      alt={`${option.label} Icon`} 
                    />
                  </div>
                  <div className="space-y-1 sm:space-y-2 text-center">
                    <h2 className="text-base sm:text-lg md:text-xl font-semibold text-foreground/90">
                      {option.label}
                    </h2>
                    <p className="text-xs sm:text-sm text-muted-foreground/70">
                      {option.disabled ? 'Coming Soon' : 'Click to continue'}
                    </p>
                  </div>
                </div>

                {/* Enhanced Hover Effect */}
                <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-primary/5 via-primary/10 to-secondary/5 opacity-0 group-hover:opacity-100 transition-all duration-500 blur-sm" />
                <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-primary/10 to-secondary/10 opacity-0 group-hover:opacity-100 transition-all duration-500" />
              </Link>
            ))}
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="border-t border-border/40 py-3 sm:py-4 backdrop-blur-sm supports-[backdrop-filter]:bg-background/60">
        <div className="container mx-auto px-3 sm:px-4 text-center text-xs sm:text-sm text-muted-foreground/70">
          © {new Date().getFullYear()} Pan Medical. All rights reserved.
        </div>
      </footer>
    </div>
  )
}

// Add these to your global CSS file
export const globalStyles = `
@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes slow-drift {
  0% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(2%, 2%) rotate(1deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

@keyframes slow-drift-reverse {
  0% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(-2%, -2%) rotate(-1deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

.animate-slow-drift {
  animation: slow-drift 20s ease-in-out infinite;
}

.animate-slow-drift-reverse {
  animation: slow-drift-reverse 20s ease-in-out infinite;
}

.bg-grid-white {
  background-image: linear-gradient(to right, rgb(255 255 255 / 0.05) 1px, transparent 1px),
                    linear-gradient(to bottom, rgb(255 255 255 / 0.05) 1px, transparent 1px);
}
`;
